import * as actions from "./../catalogActions";

export default function getData(_) {
  return (dispatch) => {
    // here is where you can make async api requests for data
    return new Promise((resolve, _) => {
      const returnedResponses = [];
      const fakeDispatch = (objToDispatch) => {
        dispatch(objToDispatch);
        returnedResponses.push(objToDispatch);
        if (returnedResponses.length >= 2) {
          resolve();
        }
      };

      Promise.all([
        actions.getPopularSizes()(fakeDispatch),
        actions.getTopSellers()(fakeDispatch),
      ]);
    });
  };
}
